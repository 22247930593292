import PersonStore from "./person.Store";
import React from "react";
import { makeAutoObservable } from "mobx";

const init = () => {
  if(!localStorage.hasOwnProperty('members'))
  {
    const members = [
      { name: "李彭林", type: "技术" },
      { name: "旦增格桑", type: "技术" },
      { name: "益西边巴", type: "技术" },
      { name: "拉巴次仁", type: "技术" },
      { name: "强久", type: "技术" },
      { name: "李旭", type: "技术" },
      { name: "多吉仁青", type: "技术" },
      { name: "赵耀", type: "技术" },
      { name: "扬超", type: "技术" },
      { name: "陈福军", type: "技术" },
      { name: "张宏年", type: "办公室" },
      { name: "高洁", type: "办公室" },
      { name: "赵梦茹", type: "办公室" },
    ];
    localStorage.setItem("members", JSON.stringify(members));
  }
}


const getall = () => {
  // init the data
  init()
  const list = JSON.parse(localStorage.getItem("members"));
  const res = [];
  list.forEach((element) => {
    const person = new PersonStore(element.name);
    if (element.type === "办公室") {
      person.setBaseMoney(5200);
      person.setBaseScore(0);
    } else {
      person.setBaseMoney(4000);
      person.setBaseScore(100);
    }
    res.push(person);
  });
  return res;
};

function decode_txt(data) {
  const arr = data.split("\n");
  const res = new Map();
  const zh_p = /[\u4e00-\u9fa5]+/g;
  const dg_p = /\d+\.?\d*/g;

  for (let line of arr) {
    line = line.trim().replace(/"/g, "").trim();
    const names = line.match(zh_p);
    const scores = line.match(dg_p);

    if (names === null || scores === null || names.length !== scores.length) {
      console.log(names);
      console.log(scores);
      continue;
    }

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const score = parseFloat(scores[i]);
      res.set(name, (res.get(name) || 0) + Math.round(score * 100) / 100);
    }
  }
  return res;
}

class Config {
  constructor() {
    makeAutoObservable(this);
    this.list = getall();
  }
  newsLines = "";
  results = "结果如下: \n";
  totalMoney = 50000;

  deleteMember(value) {
    const members = JSON.parse(localStorage.getItem("members"));
    const newMembers = members.filter((item) => item.name !== value);
    localStorage.setItem("members", JSON.stringify(newMembers));
    // reset the members
    this.list = getall();
    // reload the window
    window.location.reload();
  }

  addMember(formData) {
    const { name, type } = formData;
    console.log(name, type);

    if (type === "技术" || type === "办公室") {
      const members = JSON.parse(localStorage.getItem("members"));
      const newMembers = [...members, formData];
      localStorage.setItem("members", JSON.stringify(newMembers));
      // reset the members
      this.list = getall();
      // reload the window
      window.location.reload();
    } else {
      console.log("插入失败");
    }
  }

  reset() {
    // reset localStorage and init list
    localStorage.clear();
    init();
    // reset the members
    this.list = getall();
    // reload the window
    window.location.reload();
  }

  setNewsLines(value) {
    this.newsLines = value;
  }
  setTotalMoney(value) {
    this.totalMoney = value;
  }

  cal() {
    const res = decode_txt(this.newsLines);
    for (const [key, value] of res) {
      const index = this.list.findIndex((item) => item.name === key);
      if (index === -1) continue;
      this.list[index].setnewsScore(value / 2);
    }
  }

  calculateResult() {
    // 计算每一分对应的钱
    let totalScore = 0;
    let leftMoney = this.totalMoney;
    this.list.forEach((item) => {
      totalScore += parseFloat(item.sumScore);
      leftMoney -= parseFloat(item.baseMoney);
    });
    const score2money = leftMoney / totalScore;
    // reset the results
    this.results = "结果如下: \n";
    // 计算每个人的钱
    // 看一下最后的总和对不对
    let checkTotal = 0;
    this.list.forEach((item) => {
      let sumMoney = Math.round(item.sumScore * score2money + parseFloat(item.baseMoney));
      checkTotal += sumMoney;
      item.setSumMoney(sumMoney);
    });
    if (Math.abs(checkTotal - this.totalMoney) >= 1) {
      const index = this.list.findIndex((item) => item.name === "李彭林");
      this.list[index].setSumMoney(
        this.totalMoney - checkTotal + this.list[index].sumMoney
      );
    }
    // 每个人的分数以及总分数
    let outputs = "";
    this.list.forEach((item) => {
      outputs += `${item.name}: ${item.newsScore * 2}（大、小屏）÷2=${
        item.newsScore
      }（站内分数）+${item.baseScore}（基础分）+${item.bonus}（补助）=${
        item.sumScore
      } \n`;
    });
    outputs += `所有人加权后的总分数（站内+基础+补助）:${totalScore} \n`;
    //每个人的奖金计算
    this.list.forEach((item) => {
      outputs += `${item.name}：${item.sumScore}*${score2money.toFixed(4)}+${
        item.baseMoney
      }=${item.sumMoney} \n`;
    });

    this.results += outputs;
  }
}

const gjConfig = new Config();
const context = React.createContext(gjConfig);
const GjStore = () => React.useContext(context);
export { GjStore};
