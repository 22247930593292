import { UserOutlined, UserDeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import "./App.css";
import { GjStore} from "./store";
import { observer } from "mobx-react-lite";
import {useState} from 'react';
import {
  Input,
  Button,
  Row,
  Col,
  Layout,
  Divider,
  Table,
  Popconfirm,
  Form,
} from "antd";

const { TextArea } = Input;
const { Header, Content } = Layout;
const { Column } = Table;


const App = () => {
  const gjstore = GjStore();
  const headerStyle = {
    backgroundColor: "#ffffff", // change to the color you want
    fontSize: "24px",
    color: "#141414",
    fontFamily: "Roboto",
  };
  const contentStyle = {
    backgroundColor: "#fafafa", // change to the color you want
    padding: "2%",
  };

  const [formData, setFormData] = useState({
    "name": "",
    "type": "",
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]:value,
    });
  };

  return (
    <Layout>
      <Header style={headerStyle}>小高的绩效计算器</Header>

      <Content style={contentStyle}>
        <Divider orientation="left">人员增加</Divider>
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form layout="inline" style={{ maxWidth: "none", algin: "middle" }}>
            <Form.Item label="人名">
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="输入人名"
              />
            </Form.Item>
            <Form.Item label="类型">
              <Input
                type="text"
                name="type"
                value={formData.type}
                onChange={handleChange}
                placeholder="输入 技术或者办公室"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                onClick={() => gjstore.addMember(formData)}
              >
                增加
              </Button>
              {`\u00A0\u00A0\u00A0`}
              <Popconfirm
                title="恢复确定"
                description="你确定恢复到初始设置？"
                onConfirm={() => gjstore.reset()}
                okText="Yes"
                cancelText="No"
              >
                <Button danger icon={<ReloadOutlined />}>
                  恢复默认人员
                </Button>
              </Popconfirm>
            </Form.Item>
          </Form>
        </div>

        <Divider orientation="left">输入输出界面</Divider>
        <Row gutter={16} style={{ padding: "1%" }}>
          <Col className="gutter-row" span={12}>
            <TextArea
              rows={5}
              placeholder="多行复制到此处"
              onChange={(e) => gjstore.setNewsLines(e.target.value)}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <TextArea
              placeholder="输出结果展示区"
              rows={5}
              value={gjstore.results}
            />
          </Col>
        </Row>
        <Row style={{ padding: "1%" }}>
          <Col span={8} style={{ textAlign: "center" }}>
            <Button type="primary" onClick={() => gjstore.cal()}>
              计算分数
            </Button>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <Input
              addonBefore={<UserOutlined />}
              prefix="￥"
              style={{
                width: "80%",
              }}
              placeholder="可用的总绩效："
              onChange={(e) => gjstore.setTotalMoney(e.target.value)}
            />
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <Button type="primary" onClick={() => gjstore.calculateResult()}>
              计算绩效
            </Button>
          </Col>
        </Row>

        <Divider orientation="left">表格界面</Divider>

        <Table dataSource={gjstore.list} pagination={false} rowKey="name">
          <Column title="姓名" dataIndex="name" key="name" />
          <Column title="发稿分数" dataIndex="newsScore" key="name" />
          <Column
            title="基础分数"
            dataIndex="baseScore"
            key="name"
            width="50px"
            render={function (text, record, index) {
              return (
                <input
                  style={{ border: "0" }}
                  defaultValue={record.baseScore}
                  onChange={(e) => record.setBaseScore(e.target.value)}
                ></input>
              );
            }}
          />
          <Column
            title="补助"
            dataIndex="bonus"
            key="name"
            width="50px"
            render={function (text, record, index) {
              return (
                <input
                  style={{ border: "0" }}
                  defaultValue={record.bonus}
                  onChange={(e) => record.setBonus(e.target.value)}
                ></input>
              );
            }}
          />
          <Column
            title="加权分数"
            dataIndex="sumScore"
            key="name"
            shouldCellUpdate={(record, prevRecord) =>
              record.bonus !== prevRecord.bouns
            }
          />
          <Column
            title="基础绩效"
            dataIndex="baseMoney"
            key="name"
            width="75px"
            render={function (text, record, index) {
              return (
                <input
                  style={{ border: "0" }}
                  defaultValue={record.baseMoney}
                  onChange={(e) => record.setBaseMoney(e.target.value)}
                ></input>
              );
            }}
          />
          <Column
            title="本月绩效"
            dataIndex="sumMoney"
            key="name"
            width="300px"
          />
          <Column
            title="删除"
            width="50"
            render={function (text, record, index) {
              return (
                <Popconfirm
                  title="删除确定"
                  description="你确定删除这个人？"
                  onConfirm={() => gjstore.deleteMember(record.name)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger icon={<UserDeleteOutlined />}>
                    Delete
                  </Button>
                </Popconfirm>
              );
            }}
          />
        </Table>

        <table border="1" hidden="hidden">
          <thead>
            <tr>
              <th>姓名</th>
              <th>折半分数</th>
              <th>基础分数</th>
              <th>补助</th>
              <th>加权分数</th>
              <th>基础绩效</th>
              <th>本月绩效</th>
            </tr>
          </thead>
          <tbody>
            {gjstore.list.map((item) => (
              <tr key={item.name}>
                <td style={{ width: "100px" }}>{item.name}</td>
                <td>{item.newsScore}</td>
                <td>{item.baseScore}</td>
                <td>
                  <div>
                    <input
                      defaultValue={item.bonus}
                      onChange={(e) => item.setBonus(e.target.value)}
                    ></input>
                  </div>
                </td>
                <td>{item.sumScore}</td>
                <td>
                <div>
                    <input
                      defaultValue={item.baseMoney}
                      onChange={(e) => item.setBaseMoney(e.target.value)}
                    ></input>
                  </div>
                </td>
                <td>{item.sumMoney}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </Content>
    </Layout>
  );
};
export default observer(App);
