import { makeAutoObservable } from "mobx";

class PersonStore {
  constructor(name) {
    makeAutoObservable(this);
    this.name = name;
  }
  newsScore = 0;
  baseScore = 100;
  baseMoney = 4000;

  bonus = 0;

  sumScore = 0.00;
  sumMoney = 0;

  setBaseScore = (value) => {
    this.baseScore = parseFloat(value);
    this.sumScore = this.baseScore + this.newsScore + this.bonus;
    this.sumScore = this.sumScore.toFixed(2);
  };
  setBaseMoney = (value) => {
    this.baseMoney = parseFloat(value);
    this.baseMoney = value;
  };
  setBonus = (value) => {
    this.bonus = parseFloat(value);
    this.sumScore = this.baseScore + this.newsScore + this.bonus;
    this.sumScore = this.sumScore.toFixed(2);
  };
  setSumScore = (value) => {
    this.sumScore = value;
  };
  setSumMoney = (value) => {
    this.sumMoney = value;
  };
  setnewsScore = (value) => {
    this.newsScore = value;
    this.sumScore = this.baseScore + this.newsScore + this.bonus;
    this.sumScore = this.sumScore.toFixed(2);
  };
}

export default PersonStore;
